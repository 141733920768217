<template>
  <b-modal :id="id" ref="orcamentoItemEditModal" size="lg" title="Item do Orçamento" @close="close()">
    <template v-slot:default>
      <b-row>
        <b-col md="12">
          <b-form-group label="Produto" label-for="titulo">
            <b-form-input
              id="titulo"
              type="text"
              :value="item.titulo"
              placeholder="Produto"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group label="Valor unitário" label-for="valor_unitario">
            <b-form-input
              id="valor_unitario"
              type="text"
              :value="item.valor_unitario | money"
              placeholder="Valor unitário"
              readonly
              class="text-right"
            />      
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Quantidade" label-for="quantidade">
            <b-form-input
              id="quantidade"
              type="number"
              :min="item.multiplo"
              :step="item.multiplo"
              v-model="item.quantidade"
              class="text-center"
              placeholder="Quantidade"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <template v-slot:label>
              <span class="pr-1">Margem:</span>
              <b-form-radio-group
                id="margemTipoRadio"
                v-model="margemTipo"
                :options="margemTipoOptions"
                name="margemTipoRadio"
                class="d-inline"
                @change="margem()"
              ></b-form-radio-group>
            </template>
            <money
              v-show="isMargemValor"
              id="margem_valor"
              @blur.native="onBlurMargem_valor"
              type="text"
              class="form-control text-right"
              v-model="item.margem_valor"
              placeholder="Margem"
            />
            <money
              v-show="!isMargemValor"
              id="margem_percentual"
              ref="teste"
              @blur.native="onBlurMargem_percentual"
              type="text"
              class="form-control"
              v-model="item.margem_percentual"
              placeholder="Margem"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Valor c/ Margem" label-for="valor_com_margem">
            <b-form-input
              id="valor_com_margem"
              type="text"
              :value="(item.valor_unitario * 1 + item.margem_valor * 1) | money"
              placeholder="Valor c/ Margem"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group label="Valor Bruto" label-for="valor_bruto">
            <b-form-input
              id="valor_bruto"
              type="text"
              :value="( (item.valor_unitario +  item.margem_valor) * item.quantidade) | money"
              placeholder="Valor Bruto" 
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Valor IPI" label-for="valor_ipi">
            <b-form-input
              id="valor_ipi"
              type="text"
              :value=" (parseFloat(item.valor_ipi) + item.valor_margem_ipi)  * item.quantidade | money"
              placeholder="Valor IPI"
              readonly
            />            
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Valor ST" label-for="valor_st">
            <b-form-input
              id="valor_st"
              type="text"
              :value=" (parseFloat(item.valor_st) + item.valor_margem_st) * item.quantidade | money"
              placeholder="Valor ST"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Valor Total" class="font-weight-bold" label-for="valor_total">
            <b-form-input
              class="font-weight-bold"
              id="valor_total"
              type="text"
              :value=" (((item.valor_unitario + item.margem_valor) * item.quantidade * 1 ) + ((parseFloat(item.valor_ipi) + item.valor_margem_ipi) * item.quantidade) + ((parseFloat(item.valor_st) + item.valor_margem_st) * item.quantidade)) | money
              "
              placeholder="Valor Total"
              readonly
            />
          </b-form-group>
          <div class="div-loading" v-if="recalcularTributoMargem">
            <div>
              <b-spinner variant="info"></b-spinner>
            </div>
          </div>
        </b-col>
      </b-row>
      <div id="loading-modal-itens" class="none-loading"></div>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <div class="w-100">
        <div class="float-left">
          <b-button id="orcamentoItemHelp" class="help" variant="primary rounded-circle">
            <i class="fa fa-question-circle"></i>
          </b-button>
          <b-popover target="orcamentoItemHelp" triggers="hover focus">
            <template v-slot:title> Valor Total: </template>
            <span> Considera a soma do valor bruto, IPI e ST. </span>
          </b-popover>
        </div>

        <div class="div-alerta" v-if="!boVlMinimoAtingido">
          <p class="p-alerta" style="font-size: 12px;"> * Esse produto possui regra de preço mínimo e seu valor não pode ser menor que {{formatValorMoeda(item.vl_liquido_tab_coorp)}} </p>
          <p class="p-alerta" style="font-size: 12px;"> * Para vendas abaixo do valor, apenas com registro de projeto. Entre em contato com seu consultor.</p>
        </div>

        <div class="div-alerta" v-if="!isMargemDentroLimite()">
          <p class="p-alerta"> *** Percentual máximo de margem é de {{$store.state.configPadrao.limiteMaximoMargem}}% </p>
        </div>

        <b-button
          class="float-right ml-1"
          variant="success"
          :disabled="isSaving || !isMargemDentroLimite()"
          @click="salvarItem()"
        >
          <b-spinner v-if="isSaving" small></b-spinner>
          <span v-else>Salvar</span>
        </b-button>
        <b-button
          class="float-right ml-1"
          id="cancel"
          variant="danger"
          :disabled="isSaving"
          @click="close()"
        >Cancelar</b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
.help {
  padding: 0.3rem;
  padding-left: 0.4rem;
  padding-right: 0.45rem;
}
.help > i {
  font-size: large;
}
.div-loading{
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-loading span.spinner-border.text-info {
    height: 1.5em;
    width: 1.5em;
}
.div-alerta {
  width: 74%;
  float: left;
}
.p-alerta {
  color: red;
  margin-left: 3%;
}

.show-loading{
  display: block;
  background: black;
  opacity: 0.30;
  height: 100vh;
  width: 100vw;
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
}

.none-loading {
  display: none;
}
</style>

<script>
import Orcamento from "../../../services/orcamento";
import Produto from "@/services/produto";

export default {
  name: "OrcamentoItemEdit",
  props: {
    id: {
      type: String,
      default: "OrcamentoItemEdit"
    },
    value: {
      type: Object,
      default: function() {
        return new Object();
      }
    },
    orcamento: {
      type: Object,
      default: function() {
        return new Object();
      }
    },
    codigoCliente: {
      type: Number,
      default: 0
    },
    idOrcamento: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    isSaving: false,
    item: new Object(),
    margemTipoOptions: [
      { text: "R$", value: "V" },
      { text: "%", value: "P" }
    ],
    margemTipo: "V",
    recalcularTributoMargem: false,
    boVlMinimoAtingido: true,
    marcas_isentas:[
      86, 87, 187, 237, 257, 288, 320, 321
    ],
    valor_margem_original: 0,
    margem_percentual_original: 0,
    valor_ipi_original: 0,
    valor_st_original: 0,
    valor_margem_ipi_original: 0,
    valor_margem_st_original: 0,
    vl_liquido_tab_coorp_original: 0
  }),
  computed: {
    isMargemValor() {
      return this.margemTipo == "V";
    },
    formatValorMoeda(){
      return valor => Intl.NumberFormat('pt-BR', {style: "currency", currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(valor);
    }
  },
  watch: {
    value: function(value) {
      this.item = JSON.parse(JSON.stringify(value));

      if(!this.isMargemDentroLimite()){
        this.item.margem_valor = this.item.ultimaMargem.margemValor;
        this.item.margem_percentual = this.item.ultimaMargem.margemPercentual;
      }

      this.valor_margem_original = this.item.ultimaMargem.margemValor;
      this.margem_percentual_original = this.item.ultimaMargem.margemPercentual;
      this.valor_ipi_original = this.item.valor_ipi;
      this.valor_st_original = this.item.valor_st;
      this.valor_margem_ipi_original = this.item.valor_margem_ipi;
      this.valor_margem_st_original = this.item.valor_margem_st;
      this.vl_liquido_tab_coorp_original = this.item.vl_liquido_tab_coorp;

      if(!this.valida_vl_pscf()) {
        this.boVlMinimoAtingido = false;
        return;
      }

      this.boVlMinimoAtingido = true;
    }
  },
  mounted() {
    this.margem();
  },
  methods: {
    onBlurMargem_valor() {
      if (this.isMargemValor && this.isMargemDentroLimite()) {
        this.recalcularTributoMargem = true;
        this.item.margem_percentual = ((this.item.margem_valor * 1) / (this.item.valor_unitario * 1)) * 100;
        this.valida_vl_pscf();
        this.buscarValorImpostoMargem();
      } else {
        return;
      }
    },
    onBlurMargem_percentual() {
      if (!this.isMargemValor && this.isMargemDentroLimite()) {
        this.recalcularTributoMargem = true;
        this.item.margem_valor = this.item.valor_unitario * 1 * ((this.item.margem_percentual * 1) / 100);
        this.item.margem_valor = Math.round(this.item.margem_valor * 100) / 100;
        this.valida_vl_pscf();
        this.buscarValorImpostoMargem();
      } else {
        return;
      }
    },
    isMargemDentroLimite(){
      let limite = 0;
      let boMargemDentroLimite = false;
      let limiteMaximoMargem = (this.$store.state.configPadrao.limiteMaximoMargem + this.$store.state.configPadrao.limiteMaximoDivergenciaMargemOrc);

      if(this.isMargemValor){
        limite = Math.round(this.item.valor_unitario * (limiteMaximoMargem / 100) * 100) / 100;
        boMargemDentroLimite = this.item.margem_valor <= limite;
      } 
      
      if(!this.isMargemValor){
        boMargemDentroLimite = (Math.round(this.item.margem_percentual * 100) / 100) <= limiteMaximoMargem;
      }

      if(this.item.bo_valida_minimo == "S"){
        boMargemDentroLimite = true;
      }

      return boMargemDentroLimite;
    },
    close()
    {
      this.recalcularTributoMargem = true;
      let obj_loading = document.getElementById('loading-modal-itens');
      obj_loading.classList.add('show-loading');
      obj_loading.classList.remove('none-loading');
      Produto.getValoresMargem(this.item.produto_codigo, this.codigoCliente, this.margem_percentual_original, this.valor_margem_original, this.item.quantidade, this.item.cd_filial, this.idOrcamento)
          .then(response => {   
            this.item.valor_ipi = response.data.data.valor_ipi;
            this.item.valor_st = response.data.data.valor_st;
            this.item.valor_margem_ipi = response.data.data.valor_margem_ipi;
            this.item.valor_margem_st = response.data.data.valor_margem_st;
            this.item.bo_valida_minimo = response.data.data.bo_valida_vl_minimo_tab_coorp;
            this.item.vl_liquido_tab_coorp = response.data.data.vl_liquido_tab_coorp;
          })
          .catch(e => {
            this.$helper.showErrorResponse(e);
          })
          .finally(() => {
            this.recalcularTributoMargem = false;
            this.isSaving = false;
            document.getElementById('orcamentoItemEdit___BV_modal_content_').focus();
            obj_loading.classList.add('none-loading');
            obj_loading.classList.remove('show-loading');
            this.$refs["orcamentoItemEditModal"].hide();
          });
    },
    buscarValorImpostoMargem(){
      let vue = this;
      let total_com_margem = this.item.margem_valor + (this.item.valor_unitario);
      let obj_loading = document.getElementById('loading-modal-itens');
      obj_loading.classList.add('show-loading');
      obj_loading.classList.remove('none-loading');
      Produto.getValoresMargem(this.item.produto_codigo, this.codigoCliente, this.item.margem_percentual, this.item.margem_valor, this.item.quantidade, this.item.cd_filial, this.idOrcamento)
          .then(response => {   
            this.item.valor_ipi = response.data.data.valor_ipi;
            this.item.valor_st = response.data.data.valor_st;
            this.item.valor_margem_ipi = response.data.data.valor_margem_ipi;
            this.item.valor_margem_st = response.data.data.valor_margem_st;
            this.item.bo_valida_minimo = response.data.data.bo_valida_vl_minimo_tab_coorp;
            this.item.vl_liquido_tab_coorp = response.data.data.vl_liquido_tab_coorp;
          })
          .catch(e => {
            this.$helper.showErrorResponse(e);
          })
          .finally(() => {
            vue.recalcularTributoMargem = false;
            this.isSaving = false;
            document.getElementById('orcamentoItemEdit___BV_modal_content_').focus();
            obj_loading.classList.add('none-loading');
            obj_loading.classList.remove('show-loading');
          });
    },
    validaSalvarPsd() {
      if(this.item.bo_registro == 'false'){
        if(this.item.bo_valida_minimo == "S"){
          let valor = parseFloat(this.item.valor_unitario.toFixed(2)) + parseFloat(this.item.margem_valor.toFixed(2));
          let valor_tabela5 = parseFloat(this.item.vl_liquido_tab_coorp.toFixed(2));

          if( valor.toFixed(2) < valor_tabela5.toFixed(2)) {
            this.boVlMinimoAtingido = false;
            this.isSaving = false;
            return false;
          } else {
            this.margem();
            return true;
          }
        } else {
          this.margem();
          return true;
        }
      } else {
        this.margem();
        return true;
      }
    },
    salvarItem() {
      let valida = this.validaSalvarPsd();

      if(valida) {
        this.isSaving = true;

        Orcamento.saveOrcamentoItem(this.item)
          .then(() => {
            this.$helper.showMsg("Alterações salvas com sucesso!", "success");
            this.item.valor_total =
              this.item.quantidade *
              1 *
              (this.item.valor_unitario * 1 +
                this.item.valor_ipi * 1 +
                this.item.valor_st * 1 +
                this.item.margem_valor * 1);
            this.$emit("itemSaved", this.item);
            this.$refs["orcamentoItemEditModal"].hide();
            location.reload();
          })
          .catch(e => {
            this.$helper.showErrorResponse(e);
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
    valida_vl_pscf() {
      if(parseFloat(this.item.vl_liquido_tab_coorp) != 0 && this.item.bo_registro == 'false') {
        if(this.item.vl_liquido_tab_coorp > (this.item.valor_unitario +  this.item.margem_valor)) {
          let valor_indicado = parseFloat(this.item.vl_liquido_tab_coorp) - (parseFloat(this.item.valor_unitario) + parseFloat(this.item.margem_valor));

          this.item.margem_valor = valor_indicado + this.item.margem_valor;
          this.item.margem_percentual = ((this.item.margem_valor * 1) / (this.item.valor_unitario * 1)) * 100;
          this.buscarValorImpostoMargem();
          
          return false;
        }
      } else {
        return true;
      }
    },
    margem() {
      if(parseFloat(this.item.vl_liquido_tab_coorp) != 0 && this.item.bo_registro == 'false') {
        if(this.item.vl_liquido_tab_coorp > (this.item.valor_unitario +  this.item.margem_valor)) {
          let valor_indicado = parseFloat(this.item.vl_liquido_tab_coorp) - (parseFloat(this.item.valor_unitario) + parseFloat(this.item.margem_valor));

          this.item.margem_valor = valor_indicado + this.item.margem_valor;
          this.item.margem_percentual = ((this.item.margem_valor * 1) / (this.item.valor_unitario * 1)) * 100;
        } else {
          this.item.margem_percentual = ((this.item.margem_valor * 1) / (this.item.valor_unitario * 1)) * 100;
          this.item.margem_valor = this.item.valor_unitario * 1 * ((this.item.margem_percentual * 1) / 100);
          this.item.margem_valor = Math.round(this.item.margem_valor * 100) / 100;
        }
      } else {
        this.item.margem_percentual = ((this.item.margem_valor * 1) / (this.item.valor_unitario * 1)) * 100;
        this.item.margem_valor = this.item.valor_unitario * 1 * ((this.item.margem_percentual * 1) / 100);
        this.item.margem_valor = Math.round(this.item.margem_valor * 100) / 100;
      }
    }
  }
};
</script>
