<template>
  <div class="col-lg-12 col-md-12">
    <div class="row" v-if="opcoesPagamentos.length > 0">
      <div class="col-lg-3 col-md-3">
        <div class="flex flex-column">          
          <div class="flex flex-column form-check form-check-inline alinhamentoPagamento">
            <p class="text_pgto">Selecione a forma de pagamento*:</p>
            <select
              class="form-control"
              id="selectPagamento"
              v-model="formaPgto"
              :disabled="!orcamentoModoEdicao"
              @change="atualizarFormaPagamento()"
            >
              <option
                v-for="opcaoPgto in opcoesPagamentos"
                :key="opcaoPgto.id_forma"
                :value="opcaoPgto.id_forma"
              >
                {{ opcaoPgto.nome }}
              </option>
            </select>
          </div>
          <div class="flex flex-column form-check form-check-inline alinhamentoPagamento" v-if="permiteMostrarParcelamento()">
            <p class="text_pgto">Selecione o prazo*:</p>
            <select
              class="form-control"
              id="selectPagamento"
              v-model="condicaoPgto"
              :disabled="!orcamentoModoEdicao" 
              @change="atualizarCondicaoPagamento()" 
            >
              <option
                v-for="condicaoPgto in listarCondicoesParcelamento()"
                :key="condicaoPgto.id_condicao"
                :value="condicaoPgto.id_condicao"
              >
                {{ retornarDescricaoCondicaoPgto(condicaoPgto) }}
              </option>
            </select>
          </div>
          <div class="flex flex-column form-check form-check-inline alinhamentoPagamento" v-if="permiteMostrarPix()">
            <img src="https://www.deboravenancio.com.br/wp-content/uploads/2022/02/qrcode_chrome.png" alt="Imagem de qrcode" width="200px">
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-9">
        <p class="text_pgto">Observação:</p>
        <textarea
          class="col-lg-12 col-md-12"
          cols="196"
          rows="3"
          id="textarea"
          maxlength="1000"
          :disabled="!orcamentoModoEdicao"
          v-model="obs"
          @keyup="charCount()"
          @blur="atualizarObservacao()"
        ></textarea>
        <span>{{ charResult }} caracteres restante</span>
      </div>
    </div>
  </div>
</template>
<style>
.text_pgto {
  font-weight: bold;
}

.alinhamentoPagamento {
  margin-bottom: 3%;
  width: 100%;
}

#selectPagamento {
  width: 88%;
}

.floatRight {
  float: right;
}
</style>

<script>
import Orcamento from "../../services/orcamento";
import { EventBus } from "../../helpers/eventBus";
import GrupoPermissao from "../../services/grupoPermissao";

export default {
  name: "FormaPgto",
  props: {
    orcamentoModoEdicao: Boolean
  },
  data: () => {
    return {
      opcoesPagamentos: [],
      listaOpcoesPagamentos: [],
      formaPgto: null,
      condicaoPgto: null,
      charResult: 1000,
      disabled: false,
      obs: ""
    };
  },
  beforeMount() {
    this.validaPermissao();
    this.orcamento_id = this.$route.params.id;
    
  },
  mounted() {
    EventBus.$on("eventoAtualizarPagamento", this.atualizarPagamento);
    this.obs = this.$store.state.orcamento.dados.observacao;
    this.formaPgto = this.$store.state.orcamento.dados.id_forma;
    this.condicaoPgto = this.$store.state.orcamento.dados.id_condicao;
    this.buscarOpcoesPagamento();
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/vendas/orcamentos").then((response) => {
        if (!response.data.data) this.$router.push("/");
        else this.disabled = response.data.data.edita != "1";
      });
    },
    charCount() {
      this.charResult = 1000 - this.obs.length;
    },
    buscarOpcoesPagamento(){
      this.listaOpcoesPagamentos = [];
      this.opcoesPagamentos = [];

      Orcamento.buscarOpcoesPagamento()
        .then(response => {
          this.listaOpcoesPagamentos = response.data.data;
          this.atualizarPagamento();
        })
        .catch(error => {
          console.error(error);
          this.$helper.showErrorResponse(error);
        });
    },
    retornarDescricaoCondicaoPgto(condicaoPgto){
      let descricaoParcela = condicaoPgto.qt_parcela > 1 ? "parcelas" : "parcela";
      let intervalo = condicaoPgto.intervalo.replaceAll(",", "/");
      return `${condicaoPgto.qt_parcela} ${descricaoParcela} (${intervalo} dias) `;
    },
    atualizarFormaPagamento(){
      this.$store.commit('orcamento/SALVAR_DADO_ORCAMENTO', {id_forma: this.formaPgto});
      this.condicaoPgto = null;

      this.atualizarCondicaoPagamento();
    },
    atualizarCondicaoPagamento(){
      if(!this.permiteMostrarParcelamento() && !this.condicaoPgto && this.formaPgto){
        let index = this.opcoesPagamentos.findIndex(el => el.id_forma == this.formaPgto);
        this.condicaoPgto = this.opcoesPagamentos[index].condicoes[0].id_condicao;
      }

      this.$store.commit('orcamento/SALVAR_DADO_ORCAMENTO', {id_condicao: this.condicaoPgto});
    },
    atualizarObservacao(){
      this.$store.commit('orcamento/SALVAR_DADO_ORCAMENTO', {observacao: this.obs});
    }
  },
  computed: {
    permiteMostrarParcelamento(){
      return () => {
        if(!this.formaPgto){
          return false;
        }

        let index = this.opcoesPagamentos.findIndex(el => el.id_forma == this.formaPgto);

        return this.opcoesPagamentos[index].temParcelamento;
      }
    },
    permiteMostrarPix(){
      return () => {
        if(!this.formaPgto){
          return false;
        }

        let index = this.opcoesPagamentos.findIndex(el => el.id_forma == this.formaPgto);

        return this.opcoesPagamentos[index].ehPix && !this.orcamentoModoEdicao;
      }
    },
    atualizarPagamento(){
      return () => {
        this.carregarPagamento();

        if(this.formaPgto){
          let index = this.opcoesPagamentos.findIndex(el => el.id_forma == this.formaPgto);
        
          if(index == -1){
            this.formaPgto = null;
            this.condicaoPgto = null;
            this.atualizarFormaPagamento();
          }
        }

        if(this.condicaoPgto){
          let indexFormaPgto = this.opcoesPagamentos.findIndex(el => el.id_forma == this.formaPgto);
          let indexCondicao = this.opcoesPagamentos[indexFormaPgto].condicoes.findIndex(el => el.id_condicao == this.condicaoPgto);

          if(indexCondicao == -1){
            this.condicaoPgto = null;
            this.atualizarCondicaoPagamento();
          }
        }
      }
    },
    carregarPagamento(){
      return () => {
        this.opcoesPagamentos = [];

        if(this.listaOpcoesPagamentos.length == 0){
          return;
        }

        for(let i in this.listaOpcoesPagamentos){
          let condicaoValida = false;
          let listaCondicoes = [];
          let temParcelamento = false;
          let ehPix = false;

          for(let j in this.listaOpcoesPagamentos[i].condicoes){
            let vlMinimo = parseFloat(this.listaOpcoesPagamentos[i].condicoes[j].vl_minimo);
            let vlMaximo = parseFloat(this.listaOpcoesPagamentos[i].condicoes[j].vl_maximo);

            if(vlMinimo <= this.$store.state.orcamento.valorTotal && vlMaximo >= this.$store.state.orcamento.valorTotal){
              condicaoValida = true;
              listaCondicoes.push(this.listaOpcoesPagamentos[i].condicoes[j]);

              if(this.listaOpcoesPagamentos[i].condicoes[j].intervalo != 0){
                temParcelamento = true;
              } 
              
              if(this.listaOpcoesPagamentos[i].condicoes[j].intervalo == 0 && this.listaOpcoesPagamentos[i].nome == "Pix") {
                ehPix = true;
              }
            }
          }

          if(condicaoValida){
            let pagamento = {
              id_forma: this.listaOpcoesPagamentos[i].id_forma,
              nome: this.listaOpcoesPagamentos[i].nome,
              condicoes: listaCondicoes,
              temParcelamento: temParcelamento,
              ehPix: ehPix
            }

            this.opcoesPagamentos.push(Object.assign({}, pagamento));
          }
        }
      }
    },
    listarCondicoesParcelamento(){
      return () => {
        let index = this.opcoesPagamentos.findIndex(el => el.id_forma == this.formaPgto);

        return this.opcoesPagamentos[index].condicoes;
      }
    }
  },
  beforeDestroy() {
    EventBus.$off("eventoAtualizarPagamento", this.atualizarPagamento);
  }
};
</script>