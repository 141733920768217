var helperMsg = null;
var helperModal = null;

const validarClienteAtivo = (statusCliente) => {
    if (statusCliente != "A") {
        helperMsg.showMsg(
          "Para enviar o orçamento o Status do cliente deve ser Ativo.",
          "warning"
        );

        return false;
    }

    return true;
}

const validarCamposObrigatoriosPreenchidos = (orcamento) => {
    let listaCamposObrigatorios = [
        {campo: "id_forma", msg: "Forma de Pagamento"},
        {campo: "id_condicao", msg: "Prazo"}
    ];

    let valido = true;

    for(let i in listaCamposObrigatorios){
        if(!orcamento[listaCamposObrigatorios[i].campo]){
            valido = false;
            helperMsg.showMsg(
                `Para enviar o orçamento preencha o campo "${listaCamposObrigatorios[i].msg}".`,
                "warning"
            );
        }
    }

    return valido;
}

const validarItensSemMargem = async (itens) => {
    let lista = itens.filter(item => item.margem_valor <= 0);
    let valido = (lista.length > 0) ? false : true;

    if(!valido){
        valido = await helperModal.msgBoxConfirm(
                        "Não foi informado um valor de premiação a ser recebido nesse orçamento. Deseja enviar sem o valor de premiação?",
                        {
                        title: "Atenção",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "Sim",
                        cancelVariant: "danger",
                        cancelTitle: "Não",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true
                       }
        );

        return valido;
    } else {
        return valido;
    }
}

const validarItensComDivergenciaNaQuantidade = (itens) => {
    let lista = itens.filter(item => (item.quantidade % item.multiplo) > 0);
    let valido = (lista.length > 0) ? false : true;

    if(!valido) {
        helperMsg.showMsg(
            "Há algum produto com quantidade não adequada ao seu múltiplo.",
            "warning",
            "Produto com múltiplo divergente."
        );
    }

    return valido;
}

const existeAlteracoesNaoSalvas = (orcamento, dados, validaVazio = true) => {
    let valido = false;

    for(let i in dados){
        if( (i == "previsao_fechamento" && (dados[i] == "0000-00-00" || !dados[i]) ) || (i == "contato" && !dados[i]) ){
            continue;
        }

        if(validaVazio && !orcamento[i]){
            valido = true;
            break;
        }

        if((dados[i] != orcamento[i]) ){
            valido = true;
            break;
        }
    }

    return valido;
}

const validarSeOrcamentoJaFoiSalvo = (orcamento, infoOrcamento, orcamentoOriginal, alterarOrcamento = false) => {
    let existeAlteracoesPendente = existeAlteracoesNaoSalvas(orcamento, infoOrcamento);
    let existeDiferenteDoOrcamentoOriginal = existeAlteracoesNaoSalvas(orcamento, orcamentoOriginal, false);
    let valido = true;
    let msg = alterarOrcamento ? "alterar" : "enviar";

    if(existeAlteracoesPendente || existeDiferenteDoOrcamentoOriginal) {
        helperMsg.showMsg(
            `Alguns campos obrigatórios não foram informados, por favor salve-os antes de ${msg} o orçamento.`,
            "warning",
            "Alterações no orçamento."
        );

        valido = false;
    }

    return valido;

}

export default {
    setarConfiguracao(helperMensagem, helperBvModal){
        helperMsg = helperMensagem;
        helperModal = helperBvModal;
    },
    async validarEnvioOrcamento(statusCliente, orcamento, itens, infoOrcamento, orcamentoOriginal){
        let ehValidaMargemItens = await validarItensSemMargem(itens);

        return validarClienteAtivo(statusCliente) &&
               validarSeOrcamentoJaFoiSalvo(orcamento, infoOrcamento, orcamentoOriginal) &&
               validarCamposObrigatoriosPreenchidos(orcamento) &&
               ehValidaMargemItens &&
               validarItensComDivergenciaNaQuantidade(itens);
    },
    existeAlteracoesNaoSalvas,
    validarSeOrcamentoJaFoiSalvo
}