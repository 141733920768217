import { http } from './config'

export default {
    getByCodigo: (codigo) => {
        return http.get('pedido/pedido-completo/' + codigo)
    },
    printPedido: (codigo) => {
        return http.get('pedido/' + codigo + '/imprimir/', {
            responseType: 'blob',
            timeout: 60000,
        })
    },
    salvePedido: (data) => {
        let ipi_t = 0;
        let st_t = 0;
        let valor_t = 0;
        let margem_t = 0;
        let comissao_t = 0;
        let total_t = data.valor_total;
        let valor_unitario_margem_impostos;
        for(let i=0; i<data.itens.length; i++) {
            valor_unitario_margem_impostos = (data.itens[i].valor_unitario + data.itens[i].valor_ipi + data.itens[i].valor_st + data.itens[i].margem_valor);
            data.itens[i].valor_bruto_unit = valor_unitario_margem_impostos;
            ipi_t += data.itens[i].valor_ipi;
            st_t += data.itens[i].valor_st;
            valor_t += valor_unitario_margem_impostos;
            margem_t += data.itens[i].margem_valor;
            comissao_t += data.itens[i].valor_comissao.response;
            // total_t += (valor_unitario_margem_impostos * data.itens[i].quantidade);
            data.itens[i].valor_comissao = data.itens[i].valor_comissao.response
        }
        let dado = {
            orcamento_id : data.orcamento.id,
            codigo: data.orcamento.id,
            revenda_codigo: data.orcamento.revenda_codigo,
            cliente_codigo: data.orcamento.cliente_codigo,
            total_ipi: ipi_t,
            total_st: st_t,
            total_valor: valor_t,
            total_margem: margem_t,
            total_comissao: comissao_t,
            total: total_t,
            itens: data.itens,
            observacao: data.orcamento.observacao,
            forma_pgto: data.orcamento.forma_pgto,
            cond_pgto: data.orcamento.cond_pgto,
            cd_filial: data.orcamento.cd_filial,
            id_forma: data.orcamento.id_forma,
            id_condicao: data.orcamento.id_condicao,
            cd_tabelapreco: data.orcamento.cd_tabelapreco,
            cd_empresa: 1
        }
        return http.post('pedido/insere_pedido/', dado)
    },
    exportarPlanilha: (codigo) => {
        return http.get("pedido/exportar_planilha/" + codigo)
    },
    baixarNotaPdf: (chave) => {
        return http.get("pedido/baixar_nota_pdf/" + chave, {
            responseType: 'blob',
            timeout: 60000
        })
    },
    baixarNotaXml: (chave) => {
        return http.get("pedido/baixar_nota_xml/" + chave, {
            responseType: 'blob',
            timeout: 60000
        })
    }
}